<template>
  <CFooter
    class="page-footer f-bg font-small white text-white"
    v-if="page != 'Demo TalentFind'"
  >
    <div class="copy-right">
      &copy; 2011 – 2023 TalentFind Solution Ltd or its affiliates
    </div>
    <div class="ml-auto d-flex flex-column">
       <a
              :href="getOrgWebsite || 'javascript:void(0)'"
              :target="getOrgWebsite ? '_blank' : '_self'"
            >
      <img class="logo-adjustment" src="/img/powered_by_talentfind_new.jpeg" />
       </a>
    </div>
  </CFooter>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "TheFooter",
  data() {
    return {
      page: "",
    };
  },
  watch: {
    "$route.name": function () {
      this.page = this.$router.currentRoute.name;
    },
  },
  computed:
  {
    ...mapGetters([
      "getOrgDetail"
    ]),
     getOrgWebsite() 
     {
      return "https://www.talentfindsolutions.com/";
    }
  }
};
</script>
<style scoped>
@media all and (max-width: 480px) {
  .copy-right {
    width: 65%;
    font-size: 9px;
  }
  .logo-adjustment {
    width: 80px !important;
    margin-right: 0px !important;
  }
}
.copy-right {
  color: #645c5c;
}
.logo-adjustment {
  width: 100px;
  margin-right: 10px;
}
</style>
